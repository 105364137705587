import React from "react";
import styled from "styled-components";

const CustomButton = styled.button`
  outline: none;
  border: ${(props) =>
    props.isDisabled ? "1px solid #aaa" : "1px solid #004481"};
  background: ${(props) =>
    props.isDisabled ? "#aaa" : props.isSolid ? "#fff" : "#004481"};
  font-size: 24px;
  font-weight: 600;
  color: ${(props) => (props.isSolid ? "#004481" : "#fff")};
  padding: 16px 24px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
  transition: 0.5s;
  &:hover {
    transition: 0.5s;
    opacity: 0.8;
  }

  @media only screen and (max-width: 1200px) {
      font-size: 18px;
  }
`;

function Button({ isSolid, style, disabled, onClick, children }) {
  return (
    <CustomButton
      isSolid={isSolid}
      style={style}
      isDisabled={disabled}
      onClick={onClick}
    >
      {children}
    </CustomButton>
  );
}

export default Button;

import React, { useState } from "react";
import MRZScan from "../assets/scanner.svg";
import styled from "styled-components";
import Input from "../components/Input";
import MrzScanModal from "../components/MRZScanModal";
import { useIntl } from "react-intl";
import RightArrow from "../assets/right-arrow.svg";
import { mrzScanRequest } from "../redux/Api";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  setCompanyName,
  setDateOfBirth,
  setDocExpiryDate,
  setDocNo,
  setDocumentType,
  setError,
  setFirstName,
  setLastName,
  setPhoneNumber,
} from "../redux/actions";

const Container = styled.div`
  width: 100%;
`;

const HeaderText = styled.h1`
  font-size: 30px;
  font-weight: 600;
  color: #2f3044;
  border-bottom: 1px solid #dde4ea;
  padding-bottom: 16px;

  @media only screen and (max-width: 1200px) {
    margin: 16px 20px;
  }
`;

const HeaderTextWithoutBorder = styled.h1`
  font-size: 30px;
  font-weight: 600;
  color: #2f3044;
  padding-bottom: 16px;

  @media only screen and (max-width: 1200px) {
    font-size: 24px;
    margin: 16px 20px;
  }
`;

const DescText = styled.h6`
  font-size: 16px;
  font-weight: 400;
  color: #848484;
  margin: 24px 0;
  white-space: break-spaces;
  @media only screen and (max-width: 1200px) {
    padding: 0 20px;
  }
`;

const SubHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2em 0;
`;

const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media only screen and (max-width: 1200px) {
    margin: 16px 20px;
  }
`;
const MRZScanContainer = styled.div`
  background: #004481;
  border-radius: 9px;
  padding: 16px;
  display: flex;
  align-items: center;
  width: 450px;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    transition: 0.5s;
    opacity: 0.8;
  }
  & > .scanner {
    width: 50px;
    height: 50px;
    background: #004d92;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & > .context {
    width: 350px;
    color: #fff;
    margin: 0 1em;
    & > h4 {
      font-size: 20px;
      font-weight: 600;
    }
    & > p {
      font-weight: 400;
      line-height: 16px;
      margin-top: 8px;
      max-width: 300px;
    }
  }
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

const ErrorText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #ff0000;
  margin-bottom: 8px;

  @media only screen and (max-width: 1200px) {
    padding: 0 20px;
  }
`;

function FormPage() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    registrationType,
    firstName,
    lastName,
    dateOfBirth,
    documentType,
    docNo,
    docExpiryDate,
    companyName,
    phoneNumber,
    error,
  } = useSelector((state) => state.form);
  const [openMrzModal, setOpenMrzModal] = useState(false);
  const [scanStatus, setScanStatus] = useState({
    isFirstScanStarted: false,
    isScanning: false,
    isMRZerror: false,
    isSuccess: false,
    errorNumber: 0,
    errorCode: "",
  });

  //R230331: Modified to be able to display error codes
  const handleScan = async () => {
    if (scanStatus?.isScanning) return;
    setScanStatus({
      isMRZerror: false,
      isFirstScanStarted: true,
      isScanning: true,
    });
    try {
      let mrzRes = await mrzScanRequest();
      // --- AWAIT ---
      const data = mrzRes.data;
      if (data?.errorNumber != 0) {
        // Reader is not available
        // Reader not in service
        // Reader not found
        // Timeout
        setScanStatus({
          errorNumber: data.errorNumber,
          errorCode: data.errorCode,
          errorText: data.errorText,
          isScanning: false,
          isMRZerror: true,
          isFirstScanStarted: true,
        });
        return;
      } // error

      if (!data?.parsed) {
        // no error code AND data filed not exists or pared field not exists.. this should never happen
        setScanStatus({
          errorNumber: 219,
          errorCode: "parseError",
          errorText: "MRZ not parsed",
          isScanning: false,
          isMRZerror: true,
          isFirstScanStarted: true,
        });
        return;
      } // not parsed
      else {
        // success, parsed
        dispatch(setError({ status: false, errorMessage: "" }));
        // Document found
        const dateFormatter = (date) => {
          let newDate = new Date(date).getTime();
          let formattedDate = new Date(newDate);
          return dayjs(formattedDate);
        };
        const readedData = data.parsed;
        dispatch(setDocNo(readedData.documentNumber));
        dispatch(setDateOfBirth(dateFormatter(readedData.dateBirth)));
        dispatch(setDocExpiryDate(dateFormatter(readedData.dateExpiration)));
        dispatch(
          setFirstName(
            `${readedData.firstName}${
              readedData?.middleName ? ` ${readedData.middleName}` : ""
            }`
          )
        );
        dispatch(
          setLastName(
            `${readedData.lastName}${
              readedData?.lastName2 ? ` ${readedData.lastName2}` : ""
            }`
          )
        );
        dispatch(
          setDocumentType(
            readedData.type === "I"
              ? "idcard"
              : readedData.type === "P"
              ? "passport"
              : ""
          )
        );
        setScanStatus({
          isScanning: false,
          isMRZerror: false,
          isFirstScanStarted: true,
        });
        setTimeout(() => {
          setOpenMrzModal(false);
          let el = document.getElementById("leftContainer");
          el?.scrollTo({ top: 3000, behavior: "smooth" });
          setScanStatus({
            isScanning: false,
            isMRZerror: false,
            isFirstScanStarted: true,
          });
        }, 500); //R230331: 2000 was too slow. BTW, why need we wait here?
      } // parsed
    } catch (err) {
      // try
      setScanStatus({
        isScanning: false,
        isMRZerror: true,
        isFirstScanStarted: true,
      });
      console.log(err);
    } // catch
  };

  return (
    <Container>
      {openMrzModal && (
        <MrzScanModal
          scanStatus={scanStatus}
          closeModal={() => {
            setScanStatus({
              isFirstScanStarted: false,
              isScanning: false,
              isMRZerror: false,
              isSuccess: false,
              errorNumber: 0,
              errorCode: "",
            });
            setOpenMrzModal(false);
          }}
          handleScan={handleScan}
        />
      )}
      <HeaderText>
        {intl.formatMessage({ id: "PERSONAL_DATA_HEADER" })}
      </HeaderText>
      <DescText>
        {intl.formatMessage({
          id:
            registrationType === "multiple"
              ? "PERSONAL_DATA_DESC_MULTIPLE_REGISTRATION"
              : "PERSONAL_DATA_DESC",
        })}
      </DescText>

      <SubHeaderContainer>
        <HeaderTextWithoutBorder>
          {intl.formatMessage({ id: "MANUAL_DATA_INPUTS" })}
        </HeaderTextWithoutBorder>
        <MRZScanContainer
          onClick={() => {
            setOpenMrzModal(true);
          }}
        >
          <div className="scanner">
            <img src={MRZScan} alt="scanner" />
          </div>
          <div className="context">
            <h4>{intl.formatMessage({ id: "MRZ_SCAN" })}</h4>
            <p>{intl.formatMessage({ id: "MRZ_SCAN_BUTTON_DESC" })}</p>
          </div>
          <img src={RightArrow} alt="right-arrow" />
        </MRZScanContainer>
      </SubHeaderContainer>

      <InputContainer>
        <Input
          isRequired
          label={intl.formatMessage({ id: "FIRST_NAME" })}
          value={firstName}
          setValue={(val) => dispatch(setFirstName(val))}
        />
        <Input
          isRequired
          label={intl.formatMessage({ id: "LAST_NAME" })}
          value={lastName}
          setValue={(val) => dispatch(setLastName(val))}
        />
        <Input
          isRequired
          label={intl.formatMessage({ id: "DATE_OF_BIRTH" })}
          error={
            error?.errorMessage ===
              intl.formatMessage({ id: "INVALID_BIRTH_DATE" }) ||
            error?.errorMessage ===
              intl.formatMessage({ id: "INVALID_BIRTH_DATE_FORMAT" })
          }
          errorText={error?.errorMessage || ""}
          isDatePicker
          value={dateOfBirth}
          setValue={(val) => dispatch(setDateOfBirth(val))}
        />
        <Input
          isRequired
          label={intl.formatMessage({ id: "TYPE_OF_DOCUMENT" })}
          isSelect
          value={documentType}
          setValue={(val) => dispatch(setDocumentType(val))}
        />
        <Input
          isRequired
          label={intl.formatMessage({ id: "DOC_NO" })}
          value={docNo}
          setValue={(val) => dispatch(setDocNo(val))}
        />
        <Input
          isRequired
          label={intl.formatMessage({ id: "DOC_EXPIRY_DATE" })}
          error={
            error?.errorMessage ===
              intl.formatMessage({ id: "DOC_DATE_EXPIRED" }) ||
            error?.errorMessage ===
              intl.formatMessage({ id: "INVALID_DATE_FORMAT" })
          }
          errorText={error?.errorMessage}
          isDatePicker
          value={docExpiryDate}
          setValue={(val) => dispatch(setDocExpiryDate(val))}
        />
        <Input
          label={intl.formatMessage({ id: "COMPANY_NAME" })}
          value={companyName}
          setValue={(val) => dispatch(setCompanyName(val))}
        />
        <Input
          label={intl.formatMessage({ id: "PHONE_NUMBER" })}
          value={phoneNumber}
          setValue={(val) => dispatch(setPhoneNumber(val))}
        />
      </InputContainer>
      {error.status && <ErrorText>{error.errorMessage}</ErrorText>}
    </Container>
  );
}

export default FormPage;

import React from "react";
import LogoBlue from "../assets/logo-blue.svg";
import styled from "styled-components";
import LanguageDropdown from "./LanguageDropdown";
import { useSelector } from "react-redux";

const Container = styled.div`
  display: flex;
  padding-top: 20px;
  padding-bottom: 60px;
  justify-content: ${(props) => (props.step === 0 ? "center" : "space-between")};
  @media only screen and (max-width: 1200px) {
    padding: 18px 20px;
    align-items: center;
    & > img {
      height: auto;
      width: 50%;
    }
  }
`;

function Header() {
  const { step } = useSelector((state) => state.form);
  return (
    <Container step={step}>
      <img src={LogoBlue} alt={"logo-blue"} />
      {step === 0 || step === 5 ? null : <LanguageDropdown isLabelBlack />}
    </Container>
  );
}

export default Header;

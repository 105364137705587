import React, { useState, useRef, useEffect, useMemo } from "react";
import en from "../assets/languageFlags/en.png";
import hu from "../assets/languageFlags/hu.png";
import de from "../assets/languageFlags/de.png";
import DropdownArrow from "../assets/language-dropdown.svg";
import DropdownArrowDark from "../assets/language-dropdown-dark.svg";
import Triangle from "../assets/triangle.svg";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "../redux/actions";

let languages = [
  {
    icon: en,
    languageTag: "en",
    languageName: "English",
  },
  {
    icon: hu,
    languageTag: "hu",
    languageName: "Hungarian",
  },
  {
    icon: de,
    languageTag: "de",
    languageName: "German",
  },
];

const Container = styled.div`
  position: relative;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

const MenuSelect = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 160px;
  justify-content: space-between;
  @media only screen and (max-width: 992px) {
    min-width: unset;
  }
`;

const LanguageFlagImg = styled.img`
  margin-right: 0.5em;
`;

const LanguageContext = styled.div`
  display: flex;
  align-items: center;
`;

const LanguageText = styled.span`
  margin-right: 0.5em;
  font-size: 20px;
  font-weight: 500;
  color: ${(props) => (props.isLabelBlack ? "#000" : "#fff")};
  font-family: "Urbanist", sans-serif;
`;

const Menu = styled.div`
  position: absolute;
  top: 60px;
  background: #fff;
  padding: 20px 0;
  border-radius: 16px;
  box-shadow: 1px 1px 15px -2px rgb(0 0 0 / 75%);
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
  height: 50px;
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
`;

const SelectedBorderContainer = styled.div`
  width: 4px;
  height: 100%;
  margin-right: 0.5em;
  display: flex;
  align-items: center;
  padding-bottom: ${(props) =>
    props.isBorder && props.selected ? "16px" : ""};
`;

const SelectedBorder = styled.div`
  width: 100%;
  height: 25px;
  background: ${(props) => (props.selected ? "#004481" : "")};
`;

const MenuContent = styled.div`
  display: flex;
  margin-right: 0.5em;
  width: 100%;
  border-bottom: ${(props) => (props.isBorder ? "1px solid #EEEEEE" : "")};
  padding-bottom: ${(props) => (props.isBorder ? "16px" : "")};
  align-items: center;
  cursor: pointer;
`;

const TriangleImg = styled.img`
  position: absolute;
  top: -8px;
  right: 24px;
`;

const LanguageMenuText = styled.span`
  font-family: "Urbanist", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #212121;
`;

function LanguageDropdown({ isLabelBlack }) {
  const dispatch = useDispatch();
  const { selectedLang } = useSelector((state) => state.user);
  const [selectedLanguage, setSelectedLanguage] = useState(
    languages.find((x) => x.languageTag === selectedLang)
  );
  const [menu, setMenu] = useState(false);
  const menuRef = useRef(null);

  useMemo(() => {
    setSelectedLanguage(languages.find((x) => x.languageTag === selectedLang));
  }, [selectedLang]);

  const languageChanger = (lang) => {
    dispatch(changeLanguage(lang));
  };

  function useOutsideClickHandler(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setMenu(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideClickHandler(menuRef);

  return (
    <Container>
      <MenuSelect onClick={() => setMenu(true)}>
        <LanguageContext>
          <LanguageFlagImg
            src={selectedLanguage.icon}
            alt={selectedLanguage.languageTag}
          />
          <LanguageText isLabelBlack={isLabelBlack}>
            {selectedLanguage.languageName}
          </LanguageText>
        </LanguageContext>
        <img
          src={isLabelBlack ? DropdownArrowDark : DropdownArrow}
          alt="dropdown-arrow"
        />
      </MenuSelect>
      {menu && (
        <Menu ref={menuRef}>
          <TriangleImg src={Triangle} alt={"triangle"} />
          {languages.map((lang, index) => (
            <MenuItem
              key={index}
              onClick={() => languageChanger(lang.languageTag)}
              selected={selectedLanguage.languageTag === lang.languageTag}
            >
              <SelectedBorderContainer
                selected={selectedLanguage.languageTag === lang.languageTag}
                isBorder={index < 2}
              >
                <SelectedBorder
                  selected={selectedLanguage.languageTag === lang.languageTag}
                  isBorder={index < 2}
                />
              </SelectedBorderContainer>
              <MenuContent isBorder={index < 2}>
                <LanguageFlagImg src={lang.icon} alt={lang.languageTag} />
                <LanguageMenuText>{lang.languageName}</LanguageMenuText>
              </MenuContent>
            </MenuItem>
          ))}
        </Menu>
      )}
    </Container>
  );
}

export default LanguageDropdown;

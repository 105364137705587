import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import ConfirmModal from "../components/ConfirmModal";
import { useIntl } from "react-intl";
import PDFModal from "../components/PDFModal";

const Container = styled.div`
  width: 100%;
  margin-bottom: 3em;
`;

const HeaderText = styled.h1`
  font-size: 30px;
  font-weight: 600;
  color: #2f3044;
  border-bottom: 1px solid #dde4ea;
  padding-bottom: 16px;

  @media only screen and (max-width: 1200px) {
    margin: 16px 20px;
  }
`;

const DescText = styled.h6`
  font-size: 16px;
  font-weight: 400;
  color: #848484;
  margin: 24px 0;
  white-space: break-spaces;
  & > a {
    color: blue;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  @media only screen and (max-width: 1200px) {
    padding: 0 20px;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 16px;

  @media only screen and (max-width: 1200px) {
    padding: 0 20px;
    & > input {
      min-width: 20px;
    }
  }
`;

const Radio = styled.input`
  width: 2em;
  height: 2em;
  accent-color: #004481;
  margin-right: 1em;
`;

const GrayText = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: #848484;
`;

const BoldText = styled.b`
  font-size: 16px;
  font-weight: 600;
  color: #212121;
  margin-left: 0.3em;
`;

const ErrorText = styled.label`
  font-size: 16px;
  font-weight: 500;
  color: #ff0000;
  margin-bottom: 8px;
`;

function DataProtection({ expiry, setExpiry, setError }) {
  const intl = useIntl();
  const { registrationType } = useSelector((state) => state.form);
  const [confirmModal, setConfirmModal] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);

  useMemo(() => {
    if (!expiry) return;
    if (expiry === "0") {
      window.scroll({ top: 0, behavior: "smooth" });
      document.body.style.overflow = "hidden";
      setConfirmModal(true);
    } else {
      setError({ status: false, errorMessage: "" });
    }
  }, [expiry]);

  return (
    <Container>
      {confirmModal && (
        <ConfirmModal
          closeModal={() => {
            setConfirmModal(false);
            setExpiry("");
            document.body.style.overflow = "auto";
          }}
        />
      )}
      {pdfModal && <PDFModal closeModal={() => setPdfModal(false)} />}
      <HeaderText>
        {intl.formatMessage({ id: "DATA_PROTECTION_HEADER" })}
      </HeaderText>
      <DescText
        onClick={(e) => {
          if (e.target.tagName === "A") {
            setPdfModal(true);
          }
        }}
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id:
              registrationType === "multiple"
                ? "DATA_PROTECTION_DESC_MULTIPLE_REGISTRATION"
                : "DATA_PROTECTION_DESC",
          }),
        }}
      />
      {/*
            <iframe
        src="https://www.orimi.com/pdf-test.pdf#toolbar=0"
        style={{
          pointerEvents: "fill",
          width: "100%",
          height: "400px",
        }}
      />
             */}
      <RadioGroup>
        <Radio
          type="radio"
          checked={expiry === "1year"}
          onChange={(e) => (e.target.checked ? setExpiry("1year") : null)}
        />
        <GrayText onClick={() => setExpiry("1year")}>
          {intl.formatMessage({ id: "STORE_MY_DATA_FOR" })}
          <BoldText onClick={() => setExpiry("1year")}>
            {intl.formatMessage({ id: "1_YEAR" })}
          </BoldText>
        </GrayText>
      </RadioGroup>
      <RadioGroup>
        <Radio
          type="radio"
          checked={expiry === "72hours"}
          onChange={(e) => (e.target.checked ? setExpiry("72hours") : null)}
        />
        <GrayText onClick={() => setExpiry("72hours")}>
          {intl.formatMessage({ id: "STORE_MY_DATA_FOR" })}

          <BoldText onClick={() => setExpiry("72hours")}>
            {intl.formatMessage({ id: "72_HOURS" })}
          </BoldText>
        </GrayText>
      </RadioGroup>
      <RadioGroup>
        <Radio
          type="radio"
          checked={expiry === "0"}
          onChange={(e) => (e.target.checked ? setExpiry("0") : null)}
        />
        <GrayText onClick={() => setExpiry("0")}>
          {intl.formatMessage({ id: "DO_NOT_ACCEPT_IT" })}
        </GrayText>
      </RadioGroup>
    </Container>
  );
}

export default DataProtection;

import React, { useMemo, useRef, useState } from "react";
import Button from "../components/Button";
import RightArrowSmBlue from "../assets/right-arrow-sm-blue.svg";
import RightArrowSm from "../assets/right-arrow-sm.svg";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { changeProcessStep, setVideoWatched } from "../redux/actions";
import ConfirmModal from "../components/ConfirmModal";
import useInterval from "../hooks/useInterval";
import KnorrVideo from "../assets/knorr-logo-video.mp4";
import { useIntl } from "react-intl";
import { visitorManagement } from "../redux/Api";

const Container = styled.div`
  width: 100%;

  @media only screen and (max-width: 1200px) {
    width: 100%;
    padding-bottom: 10vh;
  }
`;

const HeaderText = styled.h1`
  font-size: 30px;
  font-weight: 600;
  color: #2f3044;
  border-bottom: 1px solid #dde4ea;
  padding-bottom: 16px;

  @media only screen and (max-width: 1200px) {
    margin: 16px 20px;
  }
`;

const DescText = styled.h6`
  font-size: 16px;
  font-weight: 400;
  color: #848484;
  margin: 24px 0;
  white-space: break-spaces;

  & > b {
    color: #000;
  }
  @media only screen and (max-width: 1200px) {
    padding: 0 20px;
  }
`;

const ArrowLeftImg = styled.img`
  margin: auto 0.5em auto;
  transform: rotate(180deg);
`;

const ArrowImg = styled.img`
  margin: auto 0 auto 0.5em;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 3em 0 5em 0;
  justify-content: space-between;
  @media only screen and (max-width: 1200px) {
    padding: 0 20px;
  }
`;

const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 16px;

  @media only screen and (max-width: 1200px) {
    padding: 0 20px;
    & > input {
      min-width: 20px;
    }
  }
`;

const Radio = styled.input`
  width: 2em;
  height: 2em;
  accent-color: #004481;
  margin-right: 1em;
`;

const GrayText = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: #848484;
`;

const Video = styled.video`
  width: 100%;
  max-height: 400px;
  border-radius: 16px;
  @media only screen and (max-width: 1200px) {
    padding: 0 20px;
    width: -webkit-fill-available;
  }
`;

const ErrorText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #ff0000;
  margin-bottom: 8px;

  @media only screen and (max-width: 1200px) {
    padding: 0 20px;
  }
`;

function VideoPage(props) {
  const intl = useIntl();
  const videoRef = useRef();
  const dispatch = useDispatch();
  const [isVideoEnded, setIsVideoEnded] = useState(false);
  const [selected, setSelected] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [playedSecs, setPlayedSecs] = useState(0);
  const [error, setError] = useState({ status: false, errorMessage: "" });
  const { registrationType } = useSelector((state) => state.form);
  const [loading, setLoading] = useState(false);
  const { registeredPersons } = useSelector((state) => state.form);
  const handlePrevious = () => {
    dispatch(changeProcessStep(2));
  };
  const handleClickNext = () => {
    if (
      isVideoEnded &&
      selected === "accepted" &&
      registrationType !== "multiple" &&
      registeredPersons.length
    ) {
      const {
        documentType,
        firstName,
        lastName,
        docNo,
        dateOfBirth,
        docExpiryDate,
        phoneNumber,
        companyName,
        expiry,
      } = registeredPersons[0];
      visitorManagement(
        documentType,
        `${firstName} ${lastName}`,
        docNo,
        dateOfBirth,
        docExpiryDate,
        phoneNumber,
        companyName,
        expiry === 1 ? "3d" : expiry === 2 ? "1y" : ""
      )
        .then(() => {
          setLoading(false);
          dispatch(changeProcessStep(4));
        })
        .catch((err) => {
          setLoading(false);
          if (err?.response?.data?.errorMessage) {
            setError({
              status: true,
              errorMessage: err?.response?.data?.errorMessage,
            });
          }
        });
    } else {
      setError({
        status: true,
        errorMessage: intl.formatMessage({ id: "VIDEO_NEXT_ERROR" }),
      });
    }
  };

  useMemo(() => {
    if (selected === "declined") {
      setConfirmModal(true);
    } else {
      setError({
        status: false,
        errorMessage: "",
      });
    }
  }, [selected]);

  useInterval(() => {
    if (videoRef?.current?.currentTime > 0) {
      setPlayedSecs(videoRef.current.currentTime);
    }
  }, 300);

  return (
    <Container>
      {confirmModal && (
        <ConfirmModal
          closeModal={(e) => {
            setConfirmModal(false);
            setSelected("");
          }}
        />
      )}
      <HeaderText>{intl.formatMessage({ id: "VIDEO_HEADER" })}</HeaderText>
      <DescText
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({ id: "VIDEO_DESC" }),
        }}
      />
      <Video
        src={
          "https://www.knorr-bremse.hu/media/documents/railvehicles/downloads/beleptetes_adatkezelesi_tajekoztato/safety_rules_NoPandemic.mp4"
        }
        controls={window.innerWidth >= 1200}
        autoPlay={window.innerWidth <= 1200}
        ref={videoRef}
        onPlaying={() => {}}
        onSeeking={(e) => {
          let delta = videoRef.current.currentTime - playedSecs;
          if (Math.abs(delta) > 0.01) {
            videoRef.current.currentTime = playedSecs;
          }
        }}
        onEnded={() => {
          dispatch(setVideoWatched());
          setIsVideoEnded(true);
          setPlayedSecs(0);
        }}
        disablePictureInPicture
        disableRemotePlayback
        controlsList={"nodownload noplaybackrate noseek"}
      />

      {error.status && <ErrorText>{error.errorMessage}</ErrorText>}
      {isVideoEnded && registrationType !== "multiple" && (
        <>
          <RadioGroup>
            <Radio
              type="radio"
              checked={selected === "accepted"}
              onChange={(e) =>
                e.target.checked ? setSelected("accepted") : null
              }
            />
            <GrayText onClick={() => setSelected("accepted")}>
              {intl.formatMessage({ id: "ACCEPT_VIDEO_CONTENT" })}
            </GrayText>
          </RadioGroup>

          <RadioGroup>
            <Radio
              type="radio"
              checked={selected === "declined"}
              onChange={(e) =>
                e.target.checked ? setSelected("declined") : null
              }
            />
            <GrayText onClick={() => setSelected("declined")}>
              {intl.formatMessage({ id: "I_DONT_ACCEPT" })}
            </GrayText>
          </RadioGroup>
        </>
      )}
      {registrationType !== "multiple" && (
        <ButtonContainer>
          <Button
            isSolid
            style={{ marginRight: "1em" }}
            onClick={handlePrevious}
          >
            <ArrowLeftImg src={RightArrowSmBlue} alt="right-arrow-blue" />
            {intl.formatMessage({ id: "PREVIOUS" })}
          </Button>
          <Button
            onClick={handleClickNext}
            disabled={selected !== "accepted" || loading}
          >
            {intl.formatMessage({ id: "NEXT" })}
            <ArrowImg src={RightArrowSm} alt="right-arrow" />
          </Button>
        </ButtonContainer>
      )}
    </Container>
  );
}

export default VideoPage;
